import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { WS_URL } from "../utils/GuiConstants";

export const useRecetasHook = (idReceta) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const getRecetas = () => axios.get(`${WS_URL}/listaRecetas`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
        });
    const getReceta = id => axios.get(`${WS_URL}/receta?id=${id}`)
        .then(res => {
            return res.data;
        })
        .catch(err => {
            console.error(err);
        });
    
    const addReceta = receta => axios.post(`${WS_URL}/saveReceta`, receta)
    .then(res => {      
      return res.data; 
    })
    .catch(err => {
      console.error(err);
    });;


    const { data: recetas } = useQuery(['recetas'], () => getRecetas(), { retry: 1 });
    const { data: receta } = useQuery(['recetas', idReceta], () => getReceta(idReceta), { retry: 1, enabled: !!idReceta });

    const {mutate: saveReceta} = useMutation({
        mutationFn: addReceta,
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries('recetas');            
            navigate(`/receta/${data.id}`);
        }
    })

    return { recetas, receta,saveReceta }
}

export default useRecetasHook;