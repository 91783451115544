import { useCallback } from "react";
import { useNavigate } from "react-router";
import useRecetasHook from "../hooks/useRecetasHook";


const RecetaCard = ({id})=>{

    const {receta:r} = useRecetasHook(id);    
    const navigate = useNavigate();
    
    const goToReceta = useCallback(id=>{
        navigate(`/receta/${id}`);
    },[navigate]);

    if (!r){
        return 'Loading..';
    }

    return  <div className="col-12 md:col-3 mb-3 px-5 shadow-2 flex flex-column" onClick={()=>goToReceta(r.id)}>
    <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
        {r.imagen && <img src={`data:image/png;base64, ${r.imagen}`} style={{maxHeight: 200}} alt="none"/>}
       {!r.imagen && <img src='/noImage.jpg' style={{maxHeight: 200}}  alt="none"/> }
    </span>
    <div className="text-900 text-xl mb-3 font-medium">{r.nombre} </div>
    <span className="text-700 line-height-3">Calorías {r.calorias}</span>
</div>;
};

export default RecetaCard;