import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import parse from 'html-react-parser';
import { Button } from "primereact/button";

import  useRecetasHook from '../hooks/useRecetasHook';

const RecetaPage = ({recetaId}) =>{
    const { id } = useParams();  

    const useId = useMemo(()=>id?? recetaId,[id, recetaId]);
    const {receta} = useRecetasHook(useId);

    const navigate = useNavigate();

    const goEditaReceta= useCallback(()=>{
        navigate(`/editaReceta/${receta?.id}`);
     },[navigate, receta]);
 
 

    return <div className="grid grid-nogutter surface-0 text-800">
    <div className="lg:col-6 md:col-6 sm:col-12  text-center md:text-left flex align-items-center ">
        <section>
            <span className="block text-3xl font-bold mb-1">
                {receta?.nombre} 
             </span>
            <span className="block text-3xl font-bold mb-1">
                <Button label="Edita receta" className="p-button-outlined mr-2" icon="pi pi-user-plus" onClick={()=>goEditaReceta()}/>
        
            </span> 
            <div className="text-2xl text-primary font-bold mb-3">{receta?.calorias} calorías</div>
             <p className="mt-0 mb-4 text-700 line-height-3"> { parse(receta?.descripcion??'<p/>') }</p>
             <p className="mt-0 mb-4 text-900 line-height-3">Fuente {receta?.fuente}</p>
             {receta?.url &&<p className="mt-0 mb-4 text-900 line-height-3">Url <a href={receta?.url} target="_blank">{receta?.url}</a></p>}
        </section>
        
         
    </div>
    <div className="lg:col-6 md:col-6 sm:col-12 overflow-hidden">
        {receta?.imagen && <img src={`data:image/png;base64, ${receta?.imagen}`}  
            className="md:ml-auto block md:h-full sm:w-full"  alt="nada"
            style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)',
             maxHeight:'600px' }} />}
        {!receta?.imagen && <img src='/noImage.jpg' alt="nada"  className="md:ml-auto block md:h-full  sm:w-full" 
        style={{ clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)', maxHeight:'600px' }}  /> }
    </div>
</div>
     ;

};

export default RecetaPage;