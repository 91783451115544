import axios from 'axios';
import WeekTable from '../components/weekPlan/WeekTable';
import { useCallback, useEffect, useState } from 'react';
import { WS_URL } from '../utils/GuiConstants';

const PlanningPage = ()=>{
  const [fecha, setFecha] = useState(new Date());
  
  
  const [data, setData] = useState([]);

  useEffect(()=>{
    axios.get(`${WS_URL}/getPlanning?d=${fecha.toLocaleDateString('es-ES').replaceAll('/','-')}`)
    .then(res => {
      setData(res.data);
    })
    .catch(err => {
      console.log('ERROR');
    });
  },[fecha]);


  const addPlanning = useCallback(planning=>{
    planning.fecha.setHours(planning.fecha.getHours() + 4); 
    axios.post(`${WS_URL}/savePlanning`, planning)
    .then(res => {
      setData(d=>[...d, res.data]);
    })
    .catch(err => {
      console.log('ERROR');
    });
  },[]);

  const deletePlanning = useCallback(planning=>{
    axios.get(`${WS_URL}/deletePlannning?id=${planning}`)
    .then(res => {
      setData(d=>d.filter(d=>d.id!==planning));
    })
    .catch(err => {
      console.log('ERROR');
    });
  },[]);

    return <div>
         <WeekTable data={data} addPlanning={addPlanning} deletePlanning={deletePlanning} setFecha={setFecha} currentWeekP={fecha}/>             
    </div>;
};

export default PlanningPage;