import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { InputText } from 'primereact/inputtext';
import  useRecetasHook from '../hooks/useRecetasHook';
import RecetaCard from "../components/RecetaCard";

const RecetasPage = ()=>{

    const { tipo } = useParams();
    
    const [search, setSearch] = useState('');

    const {recetas} = useRecetasHook();

    const recetasTipo = useMemo(()=> 
         recetas?.filter(r=> r.tipo===tipo).filter(r=> r.nombre?.toLowerCase().includes(search.toLowerCase())),
    [recetas, search, tipo]);



    return <div className="surface-0 text-center">
            <div className="mb-3 font-bold text-3xl">
                <span className="text-900">Recetas {tipo} ({recetasTipo?.length})</span>
                <span className="p-float-label">               
            </span>
            </div>
             <div className="grid">
                <div className="col-12 md:col-12 mb-12">
                    <InputText id="in" value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
                {recetasTipo?.map(r=>(
                   <RecetaCard id={r.id} key={r.id} />
                ))}               
            </div>
        </div>;

};

export default RecetasPage;