
import { useCallback, useMemo } from "react";
import RecetaForm from "../components/RecetaForm";
import { useDispatch, useSelector } from "react-redux";
import {  useParams } from "react-router";
import useRecetasHook from "../hooks/useRecetasHook";


const EditaReceta = () => {
    const dispatch = useDispatch();

    const {saveReceta} = useRecetasHook();

    const onSaveReceta = useCallback(receta=>{
        saveReceta(receta);
    },[dispatch]);

    const { id } = useParams();

    const {receta} = useRecetasHook(id);

    if (!receta){
        return 'Loading...';
    }

    return <RecetaForm onSave={onSaveReceta} receta={receta} />;
};

export default EditaReceta;