import { ConfirmDialog } from 'primereact/confirmdialog';
import { useCallback, useMemo, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';

import useRecetasHook from '../hooks/useRecetasHook';

const AddComidaDialog = ({onClose,onSave})=>{

    const {recetas} = useRecetasHook();
    const [comida, setComida ]= useState({});

    const onHandleSave= useCallback(()=>{
        onSave(comida);
    },[comida, onSave])

    const citySelectItems = [
        { label: 'Desayuno', value: 'DESAYUNO' },
        { label: 'Almuerzo', value: 'ALMUERZO' },
        { label: 'Snack', value: 'SNACK' },
        { label: 'Cena', value: 'CENA' }
    ];

    const recetasOption = useMemo(()=>recetas?.map(r=>(
        { label: `${r.nombre} (${r.calorias}) - ${r.tipo}`, value: r.id }
    )),[recetas]);

    const handleReceta = useCallback(id=>{
        setComida(com=>({...com, receta:{id}}));
    },[]);

    const handleChange = useCallback((name, value)=>{
        setComida(com=>{
            const nd = { ...com };
            nd[name] = value;
            return nd;
        });
    },[]);


    const contenido = useMemo(()=>
    <div className="flex align-items-center justify-content-center">
      
    <div className="flex align-items-center justify-content-center">
      
        <div className="col-12 lg:col-12">
            <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                    <label htmlFor="nombre" className="block text-900 font-medium mb-2">Comida</label>
                    <Dropdown value={comida?.receta?.id} options={recetasOption} onChange={(e) => handleReceta(e.value)} 
                    placeholder="Selecciona receta" filter/>

                </div>
                <div className="field mb-4 col-12">
                   <label htmlFor="tipo" className="block text-900 font-medium mb-2">Tipo</label>
                   <SelectButton name="tipo" value={comida.tipo}  onChange={e=>handleChange('tipo', e.value)} options={citySelectItems} />
                      
                </div>
                <div className="field mb-4 col-12">
                   <label htmlFor="tipo" className="block text-900 font-medium mb-2">Fecha</label>
                   <Calendar  value={comida.fecha} onChange={(e) => handleChange('fecha', e.value)} locale='es'/>    
                </div>
                
            </div>
        <div>
     </div>
    </div>
</div>
</div>,[comida, onSave, citySelectItems, handleChange, handleReceta, recetasOption]);

    return <ConfirmDialog visible={true} onHide={onClose} message={contenido}
    header="Añadir comida a planning" icon="pi pi-exclamation-triangle" 
    accept={onHandleSave} reject={onClose} />

};

export default AddComidaDialog;
