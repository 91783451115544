import { applyMiddleware, createStore} from 'redux';
import {reducer} from './reducers/index';

import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

const composeEnhancers = composeWithDevTools({
  // Specify custom devTools options
});
//const composeEnhancers = () => {};
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['navigation']
}
const persistedReducer = persistReducer(persistConfig, reducer)

// store.js
export function configureStore(initialState = {}) {
  const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
  return store;
}
const store = configureStore();
const persistor = persistStore(store);

export default {persistor, store};