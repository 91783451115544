import { Menubar } from 'primereact/menubar';
import HomePage from "../pages/HomePage";
import PlanningPage from "../pages/PlanningPage";
import RecetasPage from '../pages/RecetasPage';
import {  Route, Routes, useNavigate} from 'react-router-dom'
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import RecetaPage from "../pages/RecetaPage";
import NuevaReceta from "../pages/NuevaReceta";
import EditaReceta from "../pages/EditaReceta";
import useRecetasHook from '../hooks/useRecetasHook';

const Main = ()=>{    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {recetas} = useRecetasHook();

    const goTo= useCallback(url=>{
        navigate(url);
     },[navigate]);

    const items = [
        {
           label:'Planning',
           icon:'pi pi-fw pi-calendar',
           command: ()=>{ goTo('/')},
           style: {borderColor: '#4baaf5'}
        },
        {
            label: `Desayunos (${recetas?.filter(r=> r.tipo==='DESAYUNO')?.length ?? '...'})`,
            icon: 'pi pi-sun',
            command: ()=>{ goTo('/recetas/DESAYUNO')}
        },
        {
            label: `Almuerzos (${recetas?.filter(r=> r.tipo==='ALMUERZO')?.length?? '...'})`,
            icon: 'pi pi-briefcase',
            command: ()=>{ goTo('/recetas/ALMUERZO')}
        },
        {
            label: `Snack (${recetas?.filter(r=> r.tipo==='SNACK')?.length?? '...'})`,
            icon: 'pi pi-briefcase',
            command: ()=>{ goTo('/recetas/SNACK')}
        },
        {
            label: `Cenas (${recetas?.filter(r=> r.tipo==='CENA')?.length?? '...'})`,
            icon: 'pi pi-moon',
            command: ()=>{ goTo('/recetas/CENA')}
        },
        {
            label: 'Nueva receta',
            icon: 'pi pi-book',
            command: ()=>{ goTo('/nuevaReceta')}
        }
     ];    


    return  <div className="surface-0">
        <Menubar model={items} className='bg-bluegray-100 p-1'/>
        
        <div className="surface-0">
            <Routes>
                <Route exact path='/' element={<HomePage/>} />
                <Route exact path='/recetas/:tipo' element={<RecetasPage />} />
                <Route exact path='/planning' element={<PlanningPage/>} />
                <Route exact path='/receta/:id' element={<RecetaPage/>} />
                <Route exact path='/nuevaReceta' element={<NuevaReceta/>} />
                <Route exact path='/editaReceta/:id' element={<EditaReceta/>} />
             

            </Routes>
        </div>
       
                     
    </div>;
     
};

export default Main;