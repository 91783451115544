import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import AddComidaDialog from '../AddComidaDialog';
import InfoComidaDialog from '../InfoComidaDialog';
import { Calendar } from 'primereact/calendar';
import useRecetasHook from '../../hooks/useRecetasHook';

const WeekTable = ({ currentWeekP, data, addPlanning, deletePlanning, setFecha }) => {
    const [openDialog, setOpenDialog] = useState(null);
    const [openInfoDialog, setOpenInfoDialog] = useState(null);

    const {recetas} = useRecetasHook();

    const getMonday = useCallback(d => {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        return new Date(d.setDate(diff));
    }, []);

    const mealTimes = ['DESAYUNO', 'ALMUERZO', 'SNACK', 'CENA'];
    const currentMonday = useMemo(() => getMonday(currentWeekP ?? new Date()), [currentWeekP, getMonday]);
    const daysOfWeek = useMemo(()=> ['Lun', 'Mar', "Miér", "Juev", "Vier", "Sáb", "Dom"],[]);

    const getComidasDia = useCallback((fecha, tipo) => {
        if (data) {
            return data
               .filter(d => fecha.toLocaleDateString('default') === new Date(d.fecha).toLocaleDateString('default'))
                .filter(d => d.tipo === tipo);
        }
        return [];
    }, [data]);


    const dataToTable = useMemo(() => {
        if (data) {
            return daysOfWeek.map((d, index) => {
                var cdate = new Date(currentMonday);
                cdate.setDate(cdate.getDate() + index);
                return ({
                    dia: cdate.toLocaleDateString('default'),
                    dateDia: cdate,
                    DESAYUNO: getComidasDia(cdate, 'DESAYUNO'),
                    ALMUERZO: getComidasDia(cdate, 'ALMUERZO'),
                    SNACK: getComidasDia(cdate, 'SNACK'),
                    CENA: getComidasDia(cdate, 'CENA'),
                    total: 1200
                });
            })
        }
        return null;
    }, [data, daysOfWeek, currentMonday, getComidasDia])

    const getColor = useCallback(dia => {
        const index = dataToTable.findIndex(d => d.dia === dia);
        switch (index) {
            case 0: return 'bg-green-100';
            case 1: return 'bg-yellow-100';
            case 2: return 'bg-cyan-100';
            case 3: return 'bg-pink-100';
            case 4: return 'bg-indigo-100';
            case 5: return 'bg-teal-100';
            case 6: return 'bg-orange-100';
            default: return '';
        }

    }, [dataToTable]);
   
    const openSavePlan = useCallback((comida, fecha) => {
        setOpenDialog(true);
    }, []);

    const openClosePlan = useCallback(() => {
        setOpenDialog(false);
    }, []);

    const abreReceta = useCallback(id => {
        setOpenInfoDialog(id);
    }, []);

    const renderCalorias = useCallback((rowData, col) => {
        const comidas = data?.filter(d=>new Date(d.fecha).toLocaleDateString('default') === rowData['dia']);
       
        let calorias = 0;
        comidas.forEach(c=>{
            calorias = calorias + c?.receta?.calorias;
        });
        return <span> {calorias} cals</span>
    },[data]);

    const renderMeal = useCallback((rowData, col) => {
        const comidas = rowData[col.field];
        const color = getColor(rowData['dia']);
        
        return  <div>        
            {comidas?.map(c => {
                let receta = c.receta;
                if (!receta.nomnbre) {
                    receta = recetas?.find(r => r.id === c.receta.id);
                }
                return < >
                    <div className="col-12 ">
                        <div className={`surface-0 shadow-2 p-1 border-1 border-50 border-round ${color}`}>
                            <div className="flex justify-content-between mb-1">
                                <div>
                                    <span className="block text-500 font-medium mb-1">{receta?.calorias} cals</span>
                                    <div className="text-900 font-medium text-l">{receta?.nombre}</div>
                                </div>
                                <div className="flex align-items-center justify-content-center "
                                    style={{ cursor: 'pointer', width: '2.5rem', height: '2.5rem' }}
                                    onClick={() => deletePlanning(c.id)}>
                                    <i className="pi pi-trash text-red-500 text-l"></i>
                                </div>
                                <div className="flex align-items-center justify-content-center "
                                    style={{ cursor: 'pointer', width: '2.5rem', height: '2.5rem' }}
                                    onClick={() => abreReceta(c.receta.id)}
                                   >
                                    <i className="pi pi-book text-blue-500 text-l"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </>;
            })}
        </div>;
    }, [deletePlanning, recetas, getColor, abreReceta]);

    const renderDia = useCallback((rowData, column) => {
        const date = new Date(rowData['dateDia']);
        var options = {  weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric',formatMatcher:'basic' };
        return <span className='text-blue-600'>            
            {date.toLocaleString('es-es', options).toUpperCase()}       
        </span>
}, []);

const cellClassName = useCallback((lala,lele)=>{
    return 'papafrita';
},[]);

    return <>
        <div className="bg-bluegray-900 text-gray-100 p-1 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
            <div className="font-bold mr-8">🔥Planning Semanal </div>
             <Calendar id="icon" value={currentWeekP} onChange={(e) => setFecha(e.value)} showIcon locale='es'/>
         
            <Button onClick={openSavePlan} icon="pi pi-plus" className="ml-4  p-button-secondary  p-button-outlined">Añadir comida </Button>
        </div>

        <DataTable value={dataToTable} responsiveLayout="stack" breakpoint="960px" cellClassName={cellClassName}>
            <Column field="dia" header="Día" body={renderDia} />
            {mealTimes.map(meal => <Column field={meal} header={meal} body={renderMeal} />)}
            <Column field="total" header="Total" body={renderCalorias}/>
        </DataTable>
        {openDialog && <AddComidaDialog onSave={addPlanning} onClose={openClosePlan} />}
        {openInfoDialog && <InfoComidaDialog id={openInfoDialog} onClose={()=>setOpenInfoDialog(null)} />}
    </>;
};

export default WeekTable;