
import RecetaPage from '../pages/RecetaPage';
import { Dialog } from 'primereact/dialog';

const InfoComidaDialog = ({onClose, id})=>{

    return <Dialog visible={true} onHide={onClose}
    header="Receta" 
    accept={onClose}  >
        <div className='sm:col-12'>
        <RecetaPage recetaId={id}/>
        </div>
 
        </Dialog>

};

export default InfoComidaDialog;
