import PrimeReact from 'primereact/api';

import 'primereact/resources/themes/md-light-deeppurple/theme.css';
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

import 'react-quill/dist/quill.snow.css';
import {Provider} from "react-redux";
import store from "./store/store";
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import Main from './components/Main';
import { locale, addLocale} from 'primereact/api';
import { QueryClient, QueryClientProvider } from 'react-query';

PrimeReact.ripple = true;
PrimeReact.inputStyle = 'filled';

addLocale('es', {
  firstDayOfWeek: 1,
  dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
  dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
  monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
  today: 'Hoy',
  clear: 'Limpiar'
});

locale('es');

function App() {
  const queryClient = new QueryClient( 
     {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5*60*1000,
    });

  return (
    <div>
      <QueryClientProvider client={queryClient}>    
        <Provider store={store.store}>
          <PersistGate loading={null} persistor={store.persistor}>
            <BrowserRouter >
              <Main />
            </BrowserRouter>
          </PersistGate>
        </Provider>
        </QueryClientProvider>
      </div>
  );
}

export default App;
