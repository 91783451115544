
import { useCallback } from "react";
import RecetaForm from "../components/RecetaForm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import useRecetasHook from "../hooks/useRecetasHook";


const NuevaReceta = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const {saveReceta} = useRecetasHook();

    const onSaveReceta = useCallback(receta=>{
        saveReceta(receta);
    },[dispatch]);


    return <RecetaForm onSave={onSaveReceta} />;
};

export default NuevaReceta;