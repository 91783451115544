import { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Editor } from 'primereact/editor';
import { SelectButton } from 'primereact/selectbutton';

import Dropzone from "react-dropzone";
import {setFicheroData} from '../helpers/recetasHelper';

const RecetaForm=({onSave, receta})=>{
    const [data, setData] = useState(receta??{});

    const citySelectItems = [
        { label: 'Desayuno', value: 'DESAYUNO' },
        { label: 'Almuerzo', value: 'ALMUERZO' },
        { label: 'Snack', value: 'SNACK' },
        { label: 'Cena', value: 'CENA' },
        { label: 'Salsas', value: 'SALSAS' }
    ];

    const updateElement = useCallback(event => {
        const name = event?.target?.name;
        const value = event?.target?.value;
       
        setData(prevState=> {
            const nd = { ...prevState };
            nd[name] = value;
            return nd;
        });
    }, [data]);

    const setImagen = useCallback(arrayb=>{
        setData((prevState) => ({ 
            ...prevState,
            imagen: arrayb
         }));
    },[]);

    const setFichero = useCallback(arrayb=>{
        setData((prevState) => ({ 
            ...prevState,
            fichero: arrayb
         }));
    },[]);

    const updateDescripcion = useCallback(arrayb=>{
        setData((prevState) => ({ 
            ...prevState,
            descripcion: arrayb
         }));
    },[]);

    const onDropImage = useCallback(acceptedFiles => {
        setFicheroData(acceptedFiles, setImagen);
    }, [setImagen]);

    const onDropFichero = useCallback(acceptedFiles => {
        setFicheroData(acceptedFiles, setFichero);
    }, [setFichero]);


    const handleSave = useCallback(()=>{
        onSave(data);
    },[onSave,data]);

    return (
        <div className="flex align-items-center justify-content-center">
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div className="text-center mb-5">
                    <div className="text-900 text-3xl font-medium mb-3">Nueva receta</div>
                </div>
                <div className="col-12 lg:col-12">
                    <div className="grid formgrid p-fluid">
                        <div className="field mb-4 col-12">
                            <label htmlFor="nombre" className="block text-900 font-medium mb-2">Nombre</label>
                            <InputText id="nombre" name="nombre" value={data.nombre} onChange={updateElement} type="text" placeholder="Nombre" className="w-full mb-3" />
                        </div>
                        <div className="field mb-4 col-12">
                            <label htmlFor="calorias" className="block text-900 font-medium mb-2">Calorías</label>
                            <InputText id="calorias" name="calorias" value={data.calorias} onChange={updateElement} className="w-full mb-3" />
                        </div>
                        <div className="field mb-4 col-12">
                            <label htmlFor="tipo" className="block text-900 font-medium mb-2">Tipo</label>
                            <SelectButton name="tipo" value={data.tipo}  onChange={updateElement} options={citySelectItems} />
                        </div>
                        <div className="field mb-4 col-12">
                            <label htmlFor="descripcion" className="block text-900 font-medium mb-2">Descripción</label>
                            <Editor style={{height:'100%'}} value={data?.descripcion} name="descripcion" 
                                onTextChange={(e) => updateDescripcion(e.htmlValue)} />
                        </div>
                        <div className="field mb-4 col-12">
                            <label htmlFor="url" className="block text-900 font-medium mb-2">Url</label>
                            <InputText id="url" type="text" value={data.url} onChange={updateElement} name="url" placeholder="Url" className="w-full mb-3" />
                        </div>
                        <div className="field mb-4 col-12">
                            <label htmlFor="fuente" className="block text-900 font-medium mb-2">Fuente</label>
                            <InputText id="fuente" value={data.fuente} name="fuente" onChange={updateElement} />

                        </div>
                        <div className="field mb-4 col-12">
                            <label className="block text-900 font-medium mb-2">Imagen ppal</label>
                            <Dropzone onDrop={onDropImage} maxFiles={1}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        <div className="field mb-4 col-12">
                            <label className="block text-900 font-medium mb-2">Fichero</label>
                            <Dropzone onDrop={onDropFichero} maxFiles={1}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                )}
                            </Dropzone>
                        </div>
                        
                    </div>
                <div>
             </div>
                    <Button label="Guardar" icon="pi pi-user" className="w-full" onClick={handleSave}/>
                </div>
            </div>
        </div>
    );

}

export default RecetaForm;